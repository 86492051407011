import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FirebaseApp from './FirebaseApp'
import moment from 'moment'
import './registerServiceWorker'
var sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl

Vue.prototype.$sanitizeUrl = sanitizeUrl
Vue.config.productionTip = false
Vue.use(FirebaseApp)

//  set up filters
Vue.filter('prettyPrice', (price) => {
  if(price) {
    return "$" + (price/100).toString();
  }
  return "";
})
Vue.filter('prettyDate', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM Do, YYYY");
  }
  return "";
})
Vue.filter('prettyDateShort', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("M/D/YY");
  }
  return "";
})
Vue.filter('prettyDateTime', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM Do, YYYY, h:mm:ssa Z");
  }
  return "";
})
Vue.filter('prettyDateDayMo', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("M/D");
  }
  return "";
})
Vue.filter('prettyDateMoName', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM");
  }
  return "";
})
Vue.filter('formatDate', (datecode) => {
  if(datecode) {
    // format datecode like 20230906
    let year = datecode.slice(0,4);
    let month = datecode.slice(4,6).replace(/0(.)/,'$1');
    let day = datecode.slice(6).replace(/0(.)/,'$1');
    return `${month}/${day}/${year}`
  }
  return "";
})
Vue.filter('limitStringLength', (str,length) => {
  if(str && length) {
    if(str.length > length) {
      return str.slice(0,length) + '...';
    }
    return str;
  }
  return "";
})
Vue.filter('limitDecimals', (num,length) => {
  if(length) {
    return Number(num).toFixed(length);
  }
  return Number(num).toFixed(0);
})
// find user before creating app, if they are logged in when page refreshed
// wrap Vue app in firebase onAuthStateChanged to check for user before creating app
Vue.auth.onAuthStateChanged((user) => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    created () {
      if(user) {
        store.dispatch('updateUser', user)
      }
    }
  })
})
