<template lang="html">
  <div v-if="message" class="message text-left row" :class="message.post_type">

    <div class="col-2 flex-shrink-1 col-icon">
      <span v-if="message.post_type == 'high_five'" class="badge-round badge-high-five"><img src="../assets/img/icon-high-five-outline.svg" alt="" width="29px" height="33px"></span>
      <span v-if="message.post_type == 'heads_up'" class="badge-round badge-heads-up"><img src="../assets/img/icon-exclamation-outline.svg" alt="!" width="10px" height="27px"></span>
      <span v-if="message.post_type == 'award'" class="badge-round badge-award"><img src="../assets/img/icon-award-outline@2x.png" alt="!" width="40px" height="40px"></span>
      <span v-if="message.post_type == 'stat'" class="badge-round badge-stat"><img src="../assets/img/icon-stat-outline@2x.png" alt="!" width="40px" height="40px"></span>
    </div>

    <div class="col-10 col-labels">
      <button class="button-channel-selector" @click="activateMessage">
        <span>
          <p v-if="message.post_type == 'heads_up'" class="snug">Head's Up!</p>
          <p v-if="message.post_type == 'high_five'" class="snug">High Five!</p>
          <p v-if="message.post_type == 'award'" class="snug">Weekly Award</p>
          <p v-if="message.post_type == 'stat'" class="snug">Weekly Stats Update</p>
          
          <h4 v-if="!citation && message.post_type == 'award' && message.award_type && message.award_type == 'weekly_practice_health'" class="citation snug">Practice Health Leader</h4>
          <h4 v-if="!citation && message.post_type == 'award' && message.award_type && message.award_type == 'weekly_team_health'" class="citation snug">Team Health Leader</h4>
          <h4 v-if="!citation && message.post_type == 'award' && message.award_type && message.award_type == 'weekly_patient_health'" class="citation snug">Patient Health Leader</h4>
          <h4 v-if="!citation && message.post_type == 'award' && message.award_type && message.award_type == 'weekly_team_growth'" class="citation snug">Team Growth Leader</h4>
          <h4 v-if="!citation && message.post_type == 'stat' && message.stat_type && message.stat_type == 'weekly_provider_care_progress'" class="citation snug">Provider Care Progress</h4>
          <h4 v-if="citation" class="citation snug">
            <em v-if="message.post_type == 'heads_up'" class="light-weight">From: </em><em v-if="message.post_type == 'high_five'" class="light-weight">To: </em>{{ citation | limitStringLength(21) }}
          </h4>
          <p v-if="!citation && message.post_type !== 'award' && message.post_type !== 'stat'" class="loading snug"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
        </span>
        <i class="far fa-angle-right"></i>
      </button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import store from '../store'
import {mapGetters} from 'vuex'

export default {
  props: ['message'],
  data () {
    return {
      citation: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  methods: {
    activateMessage() {
      store.dispatch('setCurrentMessage',this.message);
    },
    // duplicated in Messagevue
    getCitation() {
      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      // load the citation object depending on type of message
      if(this.message.post_type == 'heads_up') {
        // load author name
        if(this.message.posted_by) {
          Vue.firestore.collection('customers')
            .doc(this.custom_claims.customerId)
            .collection('users')
            .doc(this.message.posted_by)
            .get()
            .then(userQuerySnap => {
              if(userQuerySnap.exists) {
                this.citation = userQuerySnap.data().name || userQuerySnap.data().email;
              }
            })
            .catch(err => console.log(err));
        } else {
          // cancel loading
          this.citation = '';
        }
      }
      else if(this.message.post_type == 'high_five') {
        // load tagged user name
        if(this.message.tagged_user_id) {
          Vue.firestore.collection('customers')
            .doc(this.custom_claims.customerId)
            .collection('users')
            .doc(this.message.tagged_user_id)
            .get()
            .then(userQuerySnap => {
              if(userQuerySnap.exists) {
                this.citation = userQuerySnap.data().name || userQuerySnap.data().email;
              }
            })
            .catch(err => console.log(err));
        } else {
          // cancel loading
          this.citation = 'Everyone';
        }
      }
      // TODO: load location name for google review post type
      // else if(this.message.post_type == 'review') {}

    },
  },
  mounted() {
    this.getCitation();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.message {
  margin-bottom: 40px;

  h4 {
    font-size: 18px;
    line-height: 1em;
    font-weight: 700;
    margin: 0;
  }
  p.loading {
    margin-top: 3px;
  }
}
.message:last-child {
  margin-bottom: 0;
}
.message.heads_up {
  // background-color: @color-blue;
}
.message.high_five {
  // background-color: @color-orange;
}
.col-icon {
  padding-right: 0;
}
.col-labels {
  padding-left: 10px;
}
.badge-round {
  margin: 0;
}
.button-channel-selector {
  // overrides
  border-radius: 0;
	font-size: 16px;
	line-height: 1em;
	padding: 3px 0 0;
	text-decoration: none;
	box-shadow: none;
  border: none !important;
  outline: none;
  // custom
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: @dark-color;

  &:hover {
    background-color: transparent;
  }
}
.citation {
  white-space: nowrap;
}
</style>
