<template lang="html">
  <div v-if="message" class="overflow-y-scroll">
    
    <div class="message-stat" :class="message.stat_type">
      <div class="icon-lg text-center">
      </div> <!-- /.icon-lg-->
      
      <div class="col-12">
        <h2 v-if="message.stat_type == 'weekly_provider_care_progress'">Provider Care Progress Rate</h2>
        <h1 v-if="user_props && user_props.name">High Five, {{ user_props.name }}!</h1>
        <h1 v-if="!user_props || !user_props.name">High Five!</h1>

        <hr>

        <p v-if="!rangePercAvg || !rangePercAvgTitle" class="lead">Good job, everyone! No data this week. Keep it up and check back next week.</p>
        
        <div v-if="rangePercAvg && rangePercAvgTitle">
          <!-- weekly provider care progress -->
          <p class="lead" v-if="message.stat_type == 'weekly_provider_care_progress'">
            You're <strong>
              <span v-if="rangePercAvgTitle">{{ rangePercAvgTitle }}!</span>
              <span v-if="!rangePercAvgTitle">working hard!</span>
            </strong>
          </p>
          <p class="lead" v-if="message.stat_type == 'weekly_provider_care_progress'">Last week, your average care progress rate was {{ rangePercAvg | limitDecimals() }}%. Great job!</p>
          
        </div>
      </div> <!-- /.col-12 -->
      
    </div> <!-- /.message-stat-->

    <div class="message-stat-body">
      <!-- range summary from StatsProvCareProgress.vue -->
       <div v-if="rangePercAvg && rangePercAvgTitle" class="col-12 chart-key">
        <p class="snug"><strong>1-Week Average</strong></p>
         <div>
            <span
              class="color-swatch"
              :class="'color-swatch-' + decideBarColorClass(rangePercAvg)"
            ></span>
            {{ rangePercAvg }}% &mdash; 
            {{ rangePercAvgTitle }}
         </div>
       </div>

      <!-- chart from StatsProvCareProgress.vue -->
      <div class="col-12">
        <div v-if="message && statValues && statValues.length" class="chart chart-bars chart-care-progress">
          <div class="bars-container d-flex flex-row">
            <div class="labels-y">
              <p><span>100%</span>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p><span>50%</span>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
            <!-- use flex reverse to show items in correct order since they were loaded in desc but need to show in asc -->
            <div class="flex-fill d-flex flex-row flex-row-reverse justify-content-end align-items-center">
              <div
                v-for="(statVal,i) in statValues"
                class="bar-container d-flex flex-column justify-content-end align-items-center"
                :style="{width: (100/statValues.length) + '%'}"
                @click="toggleActiveBar(i)"
                :class="[
                  {active: activeBar==i},
                  decideBarColorClass(statVal.percentage),
                  {narrow: statValues.length > 14}
                ]"
              >
                <p v-if="statVal.percentage!=='error'" class="label" :style="{bottom: statVal.percentage + '%'}">{{ statVal.percentage }}%</p>
                <div v-if="statVal.percentage!=='error'" class="bar" :style="{height: statVal.percentage + '%'}"></div>
                <div v-if="statVal.percentage=='error'" class="bar bar-error"><small>no data</small></div>
              </div>
            </div>
            
          </div> <!-- /.bars-container -->
          
          <div class="labels-x d-flex flex-row flex-row-reverse justify-content-between">
            <div
              v-for="(statVal,i) in statValues"
              class="bar-label d-flex flex-column justify-content-end align-items-center text-center"
              :style="{width: (100/statValues.length) + '%'}"
            >
              <p v-show="activeBar==i" class="label-badge-value">{{ statVal.timestamp | prettyDateDayMo }}</p>
            </div>
          </div> <!-- /.labels-x -->
        </div> <!-- /.chart -->
      </div> <!-- /.col-12 -->
    </div>

  </div> <!-- /.overflow-y-scroll-->
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/compat/app'
import store from '../store'
import {mapGetters} from 'vuex'

export default {
  props: ['message'],
  data () {
    return {
      activeBar: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    // return statValues array to match chart & computed props from StatsProvCareProgress.vue
    statValues() {
      if(this.message && this.message.data && this.message.data.length) {
        return this.message.data;
      }
      return null;
    },
    rangePercAvg() {
      if(this.statValues && this.statValues.length) {
        // get sum of all non-error values
        const tot = this.statValues.reduce((partialSum,next) => {
          if(next.percentage !== 'error') {
            // console.log(Number(next.percentage).toFixed(2))
            return (Number(partialSum) + Number(next.percentage)).toFixed(2);
          }
          return partialSum;
        },0);
        // get count of non-error values
        // get count of non-error & non-zero values
        const count = this.statValues.filter(st => st.percentage !== 'error' && st.percentage > 0).length;
        // guard against dividing by zero
        if(!count || count <1) {
          return 0;
        }
        return (tot / count).toFixed(2);
      }
      return null;
    },
    rangePercAvgTitle() {
      if(!this.rangePercAvg) {
        return null;
      }
      if(this.rangePercAvg <= 30) {
        // return 'red';
        return 'Living the Way';
      }
      if(this.rangePercAvg <= 40) {
        // return 'orange';
        return 'Delivering the Way';
      }
      if(this.rangePercAvg <= 50) {
        // return 'yellow';
        return 'Growing the Way';
      }
      if(this.rangePercAvg <= 60) {
        // return 'blue';
        return 'Leading the Way';
      }
      if(this.rangePercAvg > 60) {
        // return 'green';
        return 'Teaching the Way';
      }
      return null;
    },
  },
  watch: {

  },
  methods: {
    toggleActiveBar(barIndex) {
      if(this.activeBar == barIndex) {
        this.activeBar = null;
      } else {
        this.activeBar = barIndex;
      }
    },
    decideBarColorClass(percentage) {
      if(percentage == "error") {
        return '';
      }
      if(percentage <= 30) {
        return 'red';
      }
      if(percentage <= 40) {
        return 'orange';
      }
      if(percentage <= 50) {
        return 'yellow';
      }
      if(percentage <= 60) {
        return 'blue';
      }
      if(percentage > 60) {
        return 'green';
      }
    },
    markRead() {
      if(!this.user || !this.user.uid || !this.custom_claims || !this.custom_claims.customerId || !this.message) {
        return;
      }
      // console.log('call markRead')
      let read_by_users = [];
      if(this.message.read_by_users && this.message.read_by_users.length) {
        // if the user already read it, exit
        if(this.message.read_by_users.includes(this.user.uid)) {
          // console.log('already read')
          return;
        }
        // if array exists, store it
        read_by_users = this.message.read_by_users;
      }
      // add this user
      read_by_users.push(this.user.uid)
      // add this user to message's read_by_users array
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/messages/${this.message.id}`)
        .update({
          read_by_users: read_by_users,
        })
        .then(() => {
          // console.log('message marked as read')
        })
        .catch(err => console.log(err));
    },
  },
  mounted() {
    this.markRead();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

// ----------
// message-stat
// ----------
.message-stat {
  width: 100%;
  padding-bottom: 30px;
  overflow-x: visible;
  background-color: @color-orange;
}
.message-stat .icon-lg {
  position: relative;
  height: 365px;
}
.message-stat.weekly_provider_care_progress .icon-lg {
  background: url('../assets/img/bg-award-high-five-stars@2x.png') center -50px no-repeat;
  background-size: 416px 553px;
}
.col-12 {
  background: url('../assets/img/bg-banner-light@2x.png') center top no-repeat;
  background-size: 199px 145px;
  padding: 0 30px;
}
hr {
  margin: 20px 0;
  border-top: 1px solid rgba(255,255,255,0.5);
}
h2 {
  overflow-wrap: break-word;
  text-align: center;
  margin: 0 0 5px;
}
h1 {
  overflow-wrap: break-word;
  text-align: center;
  margin: 0;
}
// for channel name when catchUp==true
.channelName {
  text-align: center;
  margin: 20px 0;
}
p.lead {
  font-size: 22px;
  line-height: 28px;
}
.overflow-y-scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}
// new
.message-stat-body {
  padding: 50px 0;
}

// ----------
// Chart styles from StatsProvCareProgress.vue
// ----------

.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}

.subtitle {
  margin: 0 0 5px;
}
h3 {
  margin: 0 0 10px;
}
.chart-care-progress {
  padding-top: 15px;

  .bars-container,
  .bar-container,
  .labels-y {
    height: 190px;
  }
  .bars-container {
    border-top: none; // override
  }
  .labels-x {
    padding-left: 45px; // account for labels-y

    p {
      border: none;
      padding: 0;
      margin: 5px 0 0;
      line-height: 1em;
      white-space: nowrap;
    }
    .bar-label {
      padding: 0 5px;
    }
  }
  .bar-container {
    width: 14.28%;
    text-align: center;
  }
  .bar {
    width: 20px;
    margin: 0 auto; // center horizontally
  }
  .bar-container.narrow .bar {
    width: 10px;
  }
  .red .bar {
    background-color: @color-salmon;
  }
  .orange .bar {
    background-color: @color-orange;
  }
  .yellow .bar {
    background-color: @color-yellow;
  }
  .blue .bar {
    background-color: @color-blue;
  }
  .green .bar {
    background-color: @color-green;
  }
  .bar-error {
    height: 100%;
    border-color: @dim-color;
    border-style: dashed;
    position: relative;
    color: @dim-color;

    > small {
      transform: rotate(270deg);
      display: block;
      line-height: 1em;
      width: 50px;
      position: absolute;
      bottom: 1.75em;
      left: -1.25em;
    }
    // &:hover {
    //   background-color: @dim-color;
    //   color: @light-color;
    // }
  }
  .bar-container .label {
    width: auto; // re-center even if overflows width
    z-index: 100;
  }
  .bar-container.active {
    cursor: pointer;

    .label {
      visibility: visible;
      opacity: 1;
    }
    .bar {
      background-color: @dark-color;
    }
  }
}
.btn-range + .btn-range {
  border-left: 1px solid @dark-color !important;
  margin-left: 1em;
  padding-left: 1em;
}
.btn-range.active {
  text-decoration: none !important;
  font-weight: bold;
  cursor: auto;
}
// new styles
.chart-key {
  margin-bottom: 30px;
}
.color-swatch {
  border: 1px solid @dark-color;
  margin-right: 5px;
}
// mobile only
.chart-key hr {
  margin: 20px 0;
}
.chart-care-progress .bar-container.narrow .bar {
  width: 5px;
}

</style>
